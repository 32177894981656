$primary: #f04e29;
$secondary: #665348;
$thirdColor: #f6f2eb;
$fourthColor: #333;
$fifthColor: #fff;
$sixthColor: #aca095;
$link-decoration: none;
$link-hover-decoration: underline;
@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");
@import url("https://www.nerdfonts.com/assets/css/webfont.css");

@font-face {
  font-family: "brandonRegular";
  src: url("./webfontkit-20191030-091158/brandongrotesque-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "brandonBold";
  src: url("./webfontkit-20191030-091158/brandongrotesque-bold-webfont.woff") format("woff");
}

$regularFont: "brandonRegular", sans-serif;
$boldFont: "brandonBold", sans-serif;

* {
  font-family: $regularFont;
  // color: $fourthColor;
  letter-spacing: 0;
}

:root {
  /* font families */
  --bold-font-family: "brandonBold", sans-serif;
  --regular-font-family: "brandonRegular", sans-serif;

  /* punch pizza colors */
  --pp-orange: #f15623;
  --pp-orange-hover: #e33710;
  --pp-yellow: #ffc20e;
  --pp-blue: #00bdf2;
  --pp-blue-hover: #00aada;
  --pp-black: #333333;
  --pp-dark-brown: #665348;
  --pp-dark-tan: #aca095;
  --pp-light-tan: #f6f2ec;
  --pp-white-hover: #f7edec;
  --pp-white-hover-rgb: 247, 237, 236;

  /* generic colors */
  --white: #ffffff;
}

body {
  background-color: #f6f2eb;
  overflow-x: hidden;
}

.row {
  margin: auto;
}

//BUTTON CSS

.regularButton {
  border-radius: 4px;
  width: 150px;
  min-width: 150px;
  height: 41px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  border-color: $primary;
}

.largeButton {
  border-radius: 4px;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  // border-color: $primary;
}

.regularButton:hover::after {
  position: absolute;
  padding-left: 15px;
}

.btn:disabled {
  background-color: $fifthColor;
  border-color: $sixthColor;
  border-width: 1px;
  color: $sixthColor !important;
  font-weight: bold;
}

.returnToPunchButton {
  border-radius: 4px;
  //width: 150px;
  min-width: 150px;
  height: 41px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  background-color: white;
  color: $primary;
  border: none;
  margin-left: 15px;
}

.favOrderBtn {
  margin: 18px 25px 0px 0px;
  background-color: $primary;
  color: white;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  border-radius: 4px;
  width: auto;
  height: 30px;

  @media (max-width: 991.99px) {
    height: 40px;
    margin-top: 12px;
  }

  //@media (min-width: 701px) {
  //  float: right;
  //  position: relative;
  //  bottom: 49px;
  //}
  //
  //@media (max-width: 700px) {
  //  font-size: 12px;
  //  height: 40px;
  //  width: 50%;
  //  margin-top: 15px;
  //  position: relative;
  //}
}

.favOrders {
  @media (max-width: 991.99px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 1rem;
    padding: 0 calc(50px + 1rem) 0 1rem;
    width: 100%;
  }
}

.ghostSelector {
  background-color: $fifthColor;
  border-color: $primary;
  color: #333333;
  border-width: 1px;
}

.ghostSelector-active {
  background-color: $primary;
  color: #ffffff !important;
}

#ghostButton {
  background-color: $fifthColor;
  border-color: $primary;
  color: $primary;
  border-width: 1px;
}

#ghostButtonlarge {
  background-color: $fifthColor;
  border-color: $primary;
  color: $primary;
  border-width: 1px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
}

#dropdownBasic1 {
  border-radius: 4px;
  height: 51px;
  text-transform: uppercase;
  font-size: 16px;
  background-color: $fifthColor;
  border-color: $secondary;
  min-width: 290px;
  text-align: left;
  color: $secondary;
}

.dropdown-toggle::after {
  display: none;
}

.buttonLoading {
  pointer-events: none;
}

.spin-loader {
  border: 2px solid transparent;
  border-top: 2px solid white;
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.spin-loader-ghost {
  border: 2px solid transparent;
  border-top: 2px solid $primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.spin-loader-black {
  border: 2px solid transparent;
  border-top: 2px solid black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.spin-loader-heat-map {
  border: 4px solid transparent;
  border-top: 2px solid black;
  border-radius: 50%;
  width: 34px !important;
  height: 34px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.hidden {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//PILLS CSS

.pill {
  background-color: #fff;
  box-shadow: 0 1px 6px #00000029;
  height: 34px;
  font-weight: 400;
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 14px;
}

.pill-text {
  margin: 0;
  text-align: center;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.pill-line-through {
  span {
    text-decoration: line-through;
    opacity: 0.5 !important;
  }

  .btn {
    opacity: 0.5;
  }
}

.fontawe::before {
  font-family: FontAwesome;
  content: "\f368";
}

//RADIO CSS

.radioobj {
  padding-bottom: 35px;
}

/* The container */
.radioCont {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioCont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $fifthColor;
  border-radius: 50%;
  border: 1px solid $secondary;
}

/* On mouse-over, add a grey background color */
.radioCont:hover input ~ .checkmark {
}

/* When the radio button is checked, add a blue background */
.radioCont input:checked ~ .checkmark {
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioCont input:checked ~ .checkmark::after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioCont .checkmark::after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $primary;
}

//CHECKBOX CSS

.checkboxobj {
  //padding-bottom: 35px;
}

/* The container */
.containerChk {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  margin-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    margin: 0;
  }
}

.selector-grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  place-items: start center;
  padding: 1em;
}

.checkbox-container-col {
  flex-direction: column;
  display: inline-flex;
  min-width: 150px;
  text-align: center;
  padding: 0 8px;
  margin-bottom: 30px;
}

@media (max-width: 650px) {
  .checkbox-container-col {
    min-width: 0;
    padding: 0;
  }

  .modifierText,
  .modifierPriceText {
    font-size: 16px !important;
  }

  .modifier-title {
    font-size: 17px !important;
    margin: 0 -5px !important;
  }

  .separator-product {
    margin: 0 -5px;
  }
}

.colCheck {
  flex-direction: column;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-container-row {
  display: inline-block;
  margin-bottom: 20px;
}

.rowCheck {
  flex-direction: row;
  display: inline-flex;
  justify-content: flex-start;

  p {
    margin-left: 15px;
  }

  .checkmarkChk {
    top: 6px;
  }
}

/* Hide the browser's default radio button */
.containerChk input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmarkChk {
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: $fifthColor;
  border-radius: 4px;
  border: 1px solid $secondary;
}

/* On mouse-over, add a grey background color */
.containerChk:hover input ~ .checkmarkChk {
}

/* When the radio button is checked, add a blue background */
.containerChk input:checked ~ .checkmarkChk {
  background-color: $primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkChk::after {
  content: "";
  position: absolute;
  display: none;
}

#iconhide {
  display: none;
  background-color: red;
  margin-bottom: 15px;
  position: relative;
}

/* Show the indicator (dot/circle) when checked */
.containerChk input:checked ~ .checkmarkChk::after {
  display: inline;
}

.containerChk input:checked ~ #iconhide::after {
  display: inline;
}

/* Style the indicator (dot/circle) */
.containerChk .checkmarkChk::after {
  display: inline;
}

.checkmarkIcon {
  height: 9px;
  width: 10px;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 4px;
  left: 4px;
}

//TEXTFIELD CSS

#textFieldLabel {
  color: $secondary;
  display: none;
  font-size: 14px;
}

.textField {
  height: 40px !important;
  font-size: 16px;
  color: $secondary;
  border-color: $secondary;
}

.textField.error {
  border-left: 6px solid red;
}

.errorMessage {
  color: red;
}

.textFieldCoupon {
  height: 40px;
  font-size: 16px;
  color: $secondary;
  border-color: $secondary;
}

//LINK CSS

.caps {
  font-size: 124px;
  color: black;
  text-decoration: underline;
  text-transform: uppercase;
  font-family: $boldFont;
}

.link-with-no-href {
  color: rgba(0, 0, 0, 0.5);
}

.link-with-no-href:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}

//ICON CSS

i {
  position: relative;
  display: inline-block;
  height: 100%;
  width: auto;
}

#ex4 .p1[data-count]::after {
  position: absolute;
  right: -10%;
  top: -10%;
  content: attr(data-count);
  font-size: 40%;
  padding: 0.2em;
  border-radius: 50%;
  line-height: 1em;
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 1em;
}

//ORDER INFO CSS

.orderInfo {
  padding: 0;
  margin: 0;
}

.orderInfoTop {
  float: right;
  text-align: right;
  font-size: 18px;
  color: white;
}

#boldInfo {
  font-family: $boldFont;
}

.white {
  color: white;
  margin: 0;
  padding: 0;
}

//BAGITEM CSS

.itemPrice {
  padding-left: 20px;
  font-size: 18px;
}

#editLink {
  font-size: 17px;
  text-decoration: underline;
}

#toppingsText {
  padding-left: 15px;
  font-size: 18px;
  margin-top: 8px;
}

.itemName {
  font-family: $boldFont;
  font-size: 20px;
}

.special {
  padding-left: 15px;
  margin-top: 0;
  font-size: 14px;
}

.toppingsCheckout {
  margin-top: 0;
  font-size: 14px;
}

//BAG CSS

.bag-icon-number-badge {
  height: 20px;
  width: 20px;
  background-color: #f04e29;
  border-radius: 50%;
  position: absolute;
  left: 25px;
  bottom: 10px;

  p {
    padding: 0;
    margin: 0;
    color: white;
    text-align: center;
    font-size: 14px;
  }
}

.bag-icon-number-badge-kiosk {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: 6px;
  bottom: 10px;

  div {
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 1px));
    padding: 0;
    margin: 0;
    margin-bottom: 3px;
    color: #f04e29;
    font-size: 14px;
  }
}

.title {
  margin: 20px 0;
  font-family: $boldFont;
  font-size: 20px;
  color: $primary;
}

.bag {
  background-color: $thirdColor;
  border-radius: 4px;
  padding: 0px;
  position: relative;
  min-height: 690px;
  max-width: 536px;
}

.insideBag {
  padding: 10px 40px;
}

.bag-item-container {
  max-height: 300px;
  overflow-y: auto;
}

.emptyBag {
  height: 380px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.emptyBagText {
  text-align: center;
  font-size: 22px;
}

.bottomBag {
  background-color: $sixthColor;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 170px;
  border-radius: 0 0 4px 4px;

  @media (max-width: 650px) {
    min-height: calc(100px + env(safe-area-inset-bottom, 0));

    .mobilePadding {
      padding-top: 25px !important;
    }
  }
}

.middleInfo {
  margin: 0 15px;
}

.separator {
  border: 1px solid $sixthColor;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bottomTextLink {
  margin-top: 18px;
}

.orderItems {
}

.boldText {
  font-family: $boldFont;
  color: $fifthColor;
}

.bold {
  font-family: $boldFont;
}

//NAVBAR CSS

.subnav {
  overflow-x: auto;
  overflow-y: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 241px;
  scroll-behavior: smooth;
}

.subnavScroll {
  top: 139px;
}

.subnav-inner {
  width: 100%;
  justify-content: center;
  gap: 8px;
}

.subnav-item {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.brand {
  position: absolute;
  left: 50%;
  top: calc(30px + env(safe-area-inset-top));
  display: block;
  margin-left: -106px;
}

.kiosk-brand {
  position: absolute;
  left: 50%;
  margin: 0 0 0 -100px;
  display: block;
  top: calc(15px + env(safe-area-inset-top, 0));

  .logo-svg {
    width: 175px;
    height: 100px;
  }
}

.linkItems {
  float: right;
}

.link-color-override {
  color: rgba(0, 0, 0, 0.5) !important;

  a {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.menuCaps {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: 20px;
  color: $secondary;
}

.bagItem {
  margin-left: 0px;
  margin-right: 0px;
  //background-color: red;
}

.navnav {
  background-image: url("./assets/images/bg-main.png");
  background-position: right;
  height: calc(69px + env(safe-area-inset-top, 0));
  position: fixed;
  width: 100%;
  box-shadow: 0 1px 1px #00000029;
  padding-top: env(safe-area-inset-top, 0);
}

.stickyNav {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 600;
  padding-bottom: 69px;
}

.spinner-border {
  color: $secondary;
  margin-top: 200px;
}

.card {
  text-align: center;

  a {
    color: #333;
    text-decoration: underline;
    text-transform: uppercase;
    font-family: $boldFont;
    font-size: 14px;
  }
}

.returnTo {
  color: $secondary;
  margin: 0;
  font-size: 14px;
  display: block;
}

.punchlink {
  color: $primary;
  text-decoration: underline;
  display: block;
}

.returnToPunch {
  margin-left: 30px;
}

.navbar-toggler,
.kiosk-navbar-toggler {
  border: none;
  background: none;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.kiosk-navbar-toggler:focus,
.kiosk-navbar-toggler:active {
  outline: none;
  box-shadow: none;
  background: none;
}

//HEADERHERO CSS
#headerHero2 {
  display: none;
}

.headerHero2 {
  display: inline;
  // position: -webkit-sticky;
  // position: sticky;
  position: fixed;
  top: 69px;
}

.headerHero1 {
  display: none;
}

.headerHero {
  height: 69px;
  background-color: $sixthColor;
  padding: 0 106px;
  box-shadow: 0px 1px 1px #00000029;
  z-index: 75;
  // position: -webkit-sticky;
  // position: sticky;
  position: fixed;
  top: calc(69px + env(safe-area-inset-top, 0));
  width: 100%;
}

.left {
  margin-top: 48px;
  color: white;
  padding: 0;
}

.right {
  text-align: right;
  color: red;
  margin-top: 25px;
}

.pageHeader {
  font-size: 35px;
  color: white;
  margin: 0px;
  padding: 0px;
}

.mobileSmallBack {
  display: none;
}

.whitecolor {
  color: white;
  height: 50px;
  text-decoration: underline;
}

//HEADERHERO SCROLL

.headerHeroScroll {
  height: 70px;
  background-color: $sixthColor;
  padding: 0 0;
  text-align: center;
}

.leftScroll {
  //margin-top: 0px;
  margin-top: 20px;
  color: white;
  padding: 0;
  // width: 100%;
  text-align: left;

  @media (max-width: 500px) {
    margin-top: 23px;
  }
}

.rightScroll {
  // display: none;
  text-align: right;
  padding-right: 16px;
  text-decoration: underline;
  color: #333333;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  font-family: $boldFont;
  font-size: 14px;
  margin-top: 13px;
}

@media (max-width: 500px) {
  .rightScroll {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 15px;
    padding-right: 15px;
  }
}

.pageHeaderScroll {
  font-size: 20px;
  color: white;
  display: inline;
}

.headerStuffScroll {
  // display: inline;
  // margin: -15px;
}

.whitecolorScroll {
  display: none;
}

.mobileSmallBackScroll {
  //display: inline-flex;
  //float: left;
  margin-left: 30px;
}

//SUBNAV CSS

.menubar {
  background-color: $fifthColor;
  align-content: center;
  height: 52px;
  overflow-x: auto;
  overflow-y: hidden;
}

.menulinks {
  color: $primary;
  font-size: 16px;
}

.help2 {
  position: absolute;
  left: 50%;
  margin-left: -420px;
}

//PRODUCT CARD

.cardd {
  background-color: $fifthColor;
  border: none;
  margin: 0;
  padding: 0;
  max-width: 340px;
  text-align: center;
  min-height: 487px;
  width: 374px;
  height: 100%;
  margin: 0px auto;
}

.cardimagecol {
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: flex;
  border-radius: 4px;
  padding: 0;
  /* max-width: 340px; */
  max-height: 245px;
  width: 90%;
  position: relative;
  margin: 20px auto 0;

  .bafala-icon {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    cursor: unset;

    .bafala-icon-svg {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 5px;
      width: 35px;
      height: 35px;
    }
  }
}

.cardimage {
  max-width: 100%;
  border-radius: inherit;
}

.card-title {
  font-size: 22px;
  font-family: $boldFont;
  margin-bottom: 10px;
}

.cardprice {
  margin-bottom: 7px;
  font-size: 20px;
}

.card-text {
  font-size: 16px;
  text-transform: capitalize;
}

.bottomLink {
  margin-top: 7px;
}

.cardsyo {
  margin: 20px 0px;
  padding: 0px;
  min-width: 374px;
}

.card-body {
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-bag-container {
  text-align: center;
  position: sticky;
  top: 0;
}

.product-bag-included {
  padding: 0 15px;
  max-height: 450px;
  overflow-x: auto;
}

.product-bag-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 300px;
  overflow: hidden;
  border-radius: 6px;
  margin: 1rem;

  img {
    width: 100%;
  }
}

.product-bag-subtotal {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.add-to-cart-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 99;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

//MENU CSS

@media (max-width: 650px) {
  .mobileMenuText {
    margin: -35px 0px -20px 0px;
  }

  .menuh2 {
    font-size: 16px;
  }
}

.background {
  background-color: $thirdColor;
}

.menu-product-container {
  max-width: 1140px;
  margin: auto;
  margin-top: 121px;
}

.menuh2 {
  text-align: center;
  color: $primary;
  text-transform: uppercase;
  font-family: $boldFont;
  font-size: 30px;
  padding: 10px 16px;
  //margin: 0 0 0 26px;
  display: inline-block;
  border-bottom: 1px solid $primary;
  border-top: 1px solid $primary;
}

.menuHeaderBorder {
  border: 0.5px solid $primary;
  width: 259px;
  margin: 0;
  padding: 0;
}

.menutitle {
  text-align: center;
  margin-top: 130px;
  margin-bottom: 12px;
  // margin-left: 46px;
}

hr {
  display: block;
  height: 1px;
  border-top: 1px solid #ccc;
  width: 100px;
  margin: 0;
}

.small {
  font-size: 17px;
}

.faqLink {
  color: $secondary;
  text-align: center;
  text-decoration: underline;
}

.faqLinkCol {
  text-align: center;
  margin-top: 20px;
}

.customizeProductPrice {
  font-size: 20px;
}

.menuSubnav {
  // position: -webkit-sticky;
  // position: sticky;
  position: fixed;
  top: calc(138px + env(safe-area-inset-top, 0));
  width: 100%;
  z-index: 300;
}

.menuContainer {
  padding-right: 100px;
}

//ICONS

.pill-container-x {
  height: 9px;
  width: 9px;
}

.add-button,
.subtract-button {
  width: 16px;
  height: 16px;
}

.big-icon {
  width: 30px;
  height: 30px;
}

button {
  background-color: none;
}

.plusminusbutton {
  border: none;
  background-color: transparent;
}

.back-button {
  color: white;
  height: 16px;
  width: 10px;
  margin-right: 10px;
  margin-top: -4px;
  padding-top: 0;
}

.accessibility {
  width: 100%;
  height: 100%;
  color: white;
}

.menu-bag {
  height: 17px;
  width: 17px;
}

.searchIcon {
  height: 16px;
  width: 16px;
}

.location {
  height: 16px;
  width: 14px;
  margin: 0;
  padding: 0;
}

.ham-icon {
  width: 20px;
  height: 12.3px;
}

.information {
  height: 26px;
  width: 26px;
  margin-left: 20px;
  margin-bottom: 10px;
  display: inline;
  margin-right: -46px;
}

.orGroup {
  height: 65px;
}

.productCardsRow {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  //width: 100%;
  margin: 0px 0px;
  justify-items: center;
  margin-bottom: 100px;
}

.editIcon {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 2px;
}

//LOCATION CSS

.leftLocations {
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 35px;
}

.locButtons {
  float: right;
  position: absolute;
  bottom: 0;
  right: 0;
}

.pickBut {
  //margin-left: 20px;
}

.delBut {
  margin-right: 12px;
}

.locationInfo {
  line-height: 1.25;
  height: 130px;
  text-align: left;
}

.locationInfoMobile {
  line-height: 1.25;
  text-align: left;
}

.bold {
  font-family: $boldFont;
}

.timeLoc {
  position: absolute;
  bottom: 0;
}

.locationInf {
  //height: 130px;
}

.locationBody {
  background-color: $thirdColor;
  padding-top: 67px;
  //padding-left: 100px;
  //padding-right: 100px;
}

.locationsList {
  text-align: center;
  margin: 0px 80px;
  padding: 0px;
}

.try {
  padding: 0;
}

.previousLocationSection {
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 30px 60px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
}

.locationSection {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 10px 60px;
  width: 100%;
  border-radius: 4px;
}

.lineBorder {
  border: 0.5px solid $sixthColor;
  width: 100%;
  // margin-top: 20px;
  margin-top: 16px;
}

.distanceP {
  float: right;
  position: absolute;
  top: 0;
  right: 0;
}

.searchBar {
  padding: 0px 0px;
  margin-bottom: 10px;
}

@media (max-width: 650px) {
  .mobileSearchHide {
    display: none;
  }

  .mobileSearchShow {
    margin-top: 155px;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 100 !important;
  }

  .searchBackground {
    height: 250px;
    margin-bottom: 0px;
    z-index: 1;
    background-color: #f6f2eb;
    position: fixed;
    width: 100%;
  }
}

@media (min-width: 651px) {
  .searchBackground {
    display: none;
  }
}

.distanceMobile {
  display: none;
}

@media (min-width: 391px) and (max-width: 650px) {
}

@media (max-width: 390px) {
}

@media (max-width: 700px) {
  .rightMobile {
    text-align: right;
  }
}

.locationsColRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 0px;

  div {
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
  }
}

.locationsMobileSection {
  margin-bottom: 20px;
  max-width: 100%;
  padding-top: 10px;
  //min-width: 345px;
}

.locationsPageCont {
  margin-top: 169px;
}

.locationsMobileCont {
  display: none;
}

//INDIVIDUAL LOCATIONS

.orderDetailsIcon {
  width: 100%;
  max-width: 550px;
  height: 100px;
}

.individualLocationButtons {
  margin-top: 50px;
  margin-bottom: 50px;
}

.individualLocationSection {
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 35px;
  width: 100%;
  border-radius: 4px;
}

//SEARCH BAR

.searchContainer {
  display: inline-flex;
  flex: 1 1 300px;
  position: relative;
  border: 1px solid $secondary;
  border-radius: 5px;
  overflow: hidden;
  height: 51px;
  background-color: $fifthColor;
}

.searchBox {
  border: 0;
  flex: 1;
}

.searchButton {
  background: #538ac5;
  border: 0;
  color: white;
  padding: 0.5rem;
  border-radius: 0;
}

.searchLib {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.searchL {
  margin-left: 20px;
}

.useLocLink {
  margin-left: 8px;
  color: $fourthColor;
  font-family: $boldFont;
  text-decoration: underline !important;
  margin-top: 3px;
}

.useLocation {
  display: inline-flex;
  flex: 1 1 300px;
  position: relative;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 10px;
}

//FORM CONTAINER

.formContainer {
  width: 536px;
}

.formText {
  font-size: 16px;
  margin-left: 50px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.backgroundColor {
  background-color: $thirdColor;
}

.orGroup {
  text-align: center;
  margin-top: 15px;
}

.guestLink {
  color: $primary;
  font-family: $boldFont;
}

.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}

//MAP

.locationsMap {
  width: 100%;
  height: calc(100vh - 200px);
}

.confirmMap {
  width: 100%;
  height: 250px;
}

.agm-map-container-inner {
  // margin: auto !important;
}

#navbarNavAltMarkup {
  background-color: white;
}

//MENUSLIDE

.menu-slide {
  background-color: $thirdColor;
  padding-top: calc(env(safe-area-inset-top) + 16px);
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed;
  z-index: 998;
}

.kiosk-menu-slide {
  background-color: white;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  text-align: center;
  position: fixed;
  z-index: 998;
}

.menuCapsSlide {
  text-transform: uppercase;
  font-size: 14px;
  color: $secondary;
  margin-bottom: 25px;
  z-index: 5;
}

.menuCapsSlideKiosk {
  text-transform: uppercase;
  font-size: 16px;
  color: #212529;
  margin-bottom: 25px;
  font-family: $boldFont;
  z-index: 5;
}

.menuCapsOrange {
  color: $primary !important;
}

.itemsSlide {
  margin-top: calc(30px + env(safe-area-inset-top, 0));
}

.pan-backdrop {
  width: 236px;
  height: 268px;
  opacity: 0.8;
  z-index: 999;
}

.panThing {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -118px;
  margin-top: -203px;
  z-index: -1;
}

.sliderBottomSection {
  //height: 60px;
  background-color: $sixthColor;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 18px 0;
}

.returnToSlide {
  color: $secondary;
  margin: 0px;
  font-size: 14px;
  display: block;
}

.punchlinkSlide {
  color: $primary;
  text-decoration: underline;
  display: block;
  margin-bottom: 25px;
}

.returnToPunchSlide {
  position: absolute;
  bottom: 219px;
  left: 50%;
  margin-left: -49px;
  text-align: center;
}

.termsLink {
  color: white;
  text-decoration: underline;
}

.returnToPunchBanner {
  width: 100px;
}

//FORMS

.formDiv {
  background-color: $thirdColor;
  margin-top: 190px;
  margin-bottom: 120px;
}

.formCont {
  max-width: 536px;
  padding: calc(25px + env(safe-area-inset-top, 0)) 0 25px;
  margin-top: 140px;
}

.firstButton {
  margin-top: 45px;
}

.button-margin {
  margin-top: 20px;
}

.formBoxText {
  color: $fourthColor;
  margin-left: 20px;
}

.orangeLink {
  color: $primary;
  font-family: $boldFont;
}

.bottomGroupLinks {
  text-align: center;
  margin-top: 80px;
}

// Hide Scrollbar
.hideScroll {
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
}

.hideScroll::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

//ORDER DETAILS

.orderDetailsLeftCol {
  margin-top: 120px;
  padding: calc(60px + env(safe-area-inset-top, 0)) 0 0 0;
}

.checkoutDetailsLeftCol {
  margin-top: 139px;
  padding: calc(env(safe-area-inset-top, 0)) 0 0 0;
}

.insideInfo {
  max-width: 700px;
  padding: 0px 30px;

  & label {
    margin-bottom: 0;
  }

  @media (max-width: 990px) {
    max-width: none;
    margin-bottom: 250px !important;
  }
}

.secondaryHeader {
  margin-top: 15px;
  color: $primary;
  font-family: $boldFont;

  .deliveryTipText {
    color: $fourthColor;
  }
}

.headerFont {
  color: $primary;
  font-family: $boldFont;
  font-size: 30px;
  text-align: left;
}

.orderDetailsRow {
  margin-bottom: 40px;
  width: 100%;
}

.timeDropdown {
  margin: 10px 0px;
}

.orderLocationInfo {
  margin: 20px 0px;
}

.specialInstructionsText {
  margin: 10px 0px;
}

.orderDetailsRightCol {
  margin-top: 140px;
  background-color: white;
  padding: 40px 30px;
  z-index: 1;
  min-height: 100vh;
}

.detailsLeft {
  text-align: left;
  margin-bottom: 5px;
}

.detailsRight {
  text-align: right;
  margin-bottom: 5px;
}

.totaltext {
  font-family: $boldFont;
}

.gifttext {
  color: red;
}

.totalTextRow {
  margin-bottom: 30px;
}

.orderDetailsBottomTotals {
  position: relative;
  padding-bottom: 30px;
  padding-right: 0px;
  padding-left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  max-width: 1000px;
  min-width: 240px;
  right: 0px;
  background-color: white;
}

.bottomDetailsButton {
  margin-top: 20px;
}

.leftAlign {
  text-align: left;
}

.dinein-name {
  margin-top: 10px;
}

.table-size-tab {
  display: grid;
  padding: 0.5em;
  place-items: center;
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
  border-right: 1px solid $primary;
  background-color: white;
  font-family: $boldFont;
  cursor: pointer;
}

.table-size-left {
  border-left: 1px solid $primary;
  border-radius: 5px 0 0 5px;
}

.table-size-right {
  border-radius: 0 5px 5px 0;
}

.selected-table-size-tab {
  color: white;
  background-color: $primary;
}

.isConfirmation {
  height: 100px;
  box-shadow: 0px -3px 6px #00000029;
}

.totalsText {
  padding-top: 40px;
}

.mobileBagItem {
  display: none;
}

.lineBorderWeb {
  border: 0.5px solid $sixthColor;
  width: 100%;
  // margin-top: 20px;
  margin-top: 16px;
}

.mobileBagItemNoInline {
  //display: none;
}

.instructionsHeaderLeft {
  text-align: left;
  font-family: $boldFont;
}

.instructionsRow {
  text-align: left;
}

.noPaddingCol {
  padding: 0px;
}

.locationConfirmCol {
  margin-top: 10px;
}

//CHECKOUT CSS

@media (max-width: 550px) {
  .mobileGift {
    padding: 0px !important;
  }
}

.couponCodeWeb {
  display: flex;
}

.couponCodeMobile {
  display: none;
}

.radioButtonText {
  margin-left: 30px;
}

// Clickable
.clickable {
  cursor: pointer;
}

// Modifiers
.modifier-title {
  text-align: center;
  font-size: 23px;
  text-transform: uppercase;
}

.separator-product {
  border: 1px solid $sixthColor;
  margin-top: 15px;
  margin-bottom: 15px;
}

.modifierText {
  font-size: 19px;
  padding-top: 5px;
}

.addition-text {
  color: $primary;
  font-size: 16px;
}

.customizeContainer {
  //padding: 0 5%;
  margin-top: 140px;
  @media (max-width: 991px) {
    margin-top: calc(140px + env(safe-area-inset-top, 0));
    padding: 20px 20px calc(154px + 3rem + env(safe-area-inset-bottom, 0));
  }
}

.modifierPriceText {
  font-size: 19px;
}

.modifyModifierText {
  font-size: 16px;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.customizeColRight {
  padding: 0 7%;
}

.customizeColLeft {
  margin-top: 48px;
}

.modifier-open {
  color: $primary !important;
}

@media (min-width: 961px) {
  .mobile-modifiers {
    display: none !important;
  }
}

@media (max-width: 960px) {
  .desktop-modifiers {
    display: none;
  }
}

@media (max-width: 1200px) {
  .modifier-title {
    background-color: white;
    padding: 15px 10px;
    font-size: 18px;
    margin: 0;
  }

  .separator-product {
    border: 1px solid $sixthColor;
    margin-top: 0;
    margin-bottom: 0;
  }
}

//ACCOUNT

.accountLeftCol {
  padding: 60px 5%;
}

.accountRightCol {
  padding: 60px 0px;
  z-index: 1;

  @media (max-width: 500px) {
    padding: 5px 0px;
  }
}

.accountHeader {
  color: $fourthColor;
  font-family: $boldFont;
  font-size: 25px;
}

.rightAlign {
  text-align: right;
}

.forwardArrow {
  height: 15px;
  width: 10px;
}

.accountItems {
  margin-top: 35px;
}

.accountItem {
  margin-top: 25px;
}

.accountItemText {
  text-transform: uppercase;
  font-family: $boldFont;
  color: $fourthColor;
}

.accountDetails {
  background-color: white;
  text-align: center;
  min-height: 300px;
  padding-bottom: 50px;

  @media (max-width: 500px) {
    min-height: 200px;
  }
}

.accountDetailsLine {
  border: 0.5px solid $sixthColor;
  width: 100%;
  // margin-top: 20px;
  margin-top: 16px;
}

.accountDetailsBottomBorder {
  border: 0.5px solid $sixthColor;
  width: 100%;
  // margin-top: 20px;
  margin-top: 16px;
  display: none;
}

.accountDetailsHeader {
  margin-top: 40px;
  color: $primary;
  font-size: 20px;
  text-transform: uppercase;
  font-family: $boldFont;

  @media (max-width: 500px) {
    margin-top: 35px;
  }
}

.accountDetailsInnerInfo {
  padding-left: 5%;
  padding-right: 5%;
}

.accountDetailsUserName {
  margin-top: 20px;
  font-family: $boldFont;
}

.accountDetailsEmail {
  margin-top: 20px;
}

.accountEditEmail {
  font-family: $boldFont;
  text-decoration: underline;
}

.accountResetPass {
  margin-top: 20px;
  text-decoration: underline;
  font-style: italic;
}

.accountLeftColMobile {
  display: none;
}

.leftSideItemsBag {
  display: none;
}

.leftSideDivider {
  display: none;
}

//PAST ORDER

.pastOrderCard {
  background-color: white;
  margin: 15px 15px;
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  text-align: center;
}

.pastOrdersDate {
  text-align: left;
  padding: 0px;
}

.pastOrdersPrice {
  text-align: right;
  padding: 0px;
}

.pastOrderName {
  font-family: $boldFont;
  text-align: left;
  padding: 0px;
}

.pastOrderSmallPrice {
  text-align: right;
  padding: 0px;
  font-size: 14px;
}

.pastOrderButton {
  margin-top: 25px;
}

.noAccountInfo {
  text-align: center;
  height: 300px;
}

.noAccountInfoLine {
  border: 0.5px solid $sixthColor;
  width: 100%;
  // margin-top: 20px;
  margin-top: 16px;
}

.no-location-text {
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 20px;
}

.viewMore {
  margin-top: 15px;
  text-decoration: underline;
}

//SAVED CARDS

.savedCardCard {
  background-color: white;
  margin: 15px 15px;
  min-width: 250px;
  max-width: 250px;
  min-height: 250px;
  text-align: center;
}

.savedCardRow {
  text-align: center;
}

.savedCardType {
  text-align: left;
  padding: 0px;
  font-size: 14px;
}

.savedCardBrand {
  text-align: right;
  padding: 0px;
  font-size: 14px;
}

.cardNumber {
  font-family: $boldFont;
  text-align: left;
  padding: 0px;
}

.cardholderName {
  text-align: left;
  padding: 0px;
}

.exp {
  text-align: left;
  padding: 0px;
}

.savedCardButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  margin-left: -75px;
}

//FAVORITE ORDERS

.favoriteOrderPrice {
  margin: 0px;
}

.favoriteOrderCard {
  background-color: $fifthColor;
  border: none;
  margin: 20px;
  padding: 0px;
  max-width: 320px;
  text-align: center;
  //width: 374px;
}

.favoriteOrderName {
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: $primary;
  font-family: $boldFont;
  font-size: 20px;
}

.favoritecardimagecol {
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: flex;
  border-radius: 4px;
  padding: 0;
  /* max-width: 340px; */
  width: 90%;
  position: relative;
  margin: 0px auto 0;
  height: 200px;

  .bafala-icon {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    cursor: unset;

    .bafala-icon-svg {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 5px;
      width: 35px;
      height: 35px;
    }
  }
}

.favoriteOrderscard-body {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

//INFORMATION POPUP

.popupInnerInfo {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.di-bafala {
  height: 23px;
  width: 30px;
  margin-right: 5px;
}

.popupLine {
  margin-bottom: 10px;
}

// Regular Bag
.modal-dialog {
  margin-top: calc(0.5rem + env(safe-area-inset-top, 0));
  margin-bottom: calc(0.5rem + env(safe-area-inset-bottom, 0));
}

.bag-modal {
  .modal-dialog {
    position: absolute;
    width: inherit;
    right: 15px;
    top: 40px;
  }

  .bag-triangle {
    width: 28px;
    height: 25px;
    position: absolute;
    background-color: $thirdColor;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -6px;
    right: 2%;
  }
}

.total {
  margin-bottom: 10px;
}

.bag-subtotal {
  margin-bottom: 12px;
  margin-top: 5px;
}

.subtotal {
  margin-bottom: 12px;
  margin-top: 18px;
}

.subtotalText,
.totalText,
.deliveryFeeText {
  color: white;
}

.deliveryFeeText {
  font-size: 16px;
}

.subtotalText {
  font-family: $boldFont;
  font-size: 20px;
}

.total-separator {
  border: 0.5px solid white;
  margin: 5px 15px;
}

.totalText {
  font-family: $boldFont;
  font-size: 20px;
}

.navbarBag {
  display: none;
}

.bagHeaderHero {
  display: none;
}

//DeliveryModal

.find-location {
  height: 16px;
  width: 16px;
}

.deliverySearchContainer {
  display: inline-flex;
  flex: 1 1 300px;
  position: relative;
  border: 1px solid $secondary;
  border-radius: 5px;
  overflow: hidden;
  height: 51px;
  background-color: $fifthColor;
  text-align: center;
  min-width: 250px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.deliveryDiv {
  text-align: center;
}

.deliveryZoneText {
  text-align: center;
  min-width: 250px;
  max-width: 250px;
  display: inline-block;
}

.deliveryZoneFailText {
  text-align: center;
  min-width: 325px;
  max-width: 325px;
  display: inline-block;
  margin-top: 15px;
}

.deliveryZoneButtons {
  margin-top: 15px;
  margin-bottom: 30px;
  padding: 0 20px;
}

.deliveryZoneCustomButton {
  height: 60px;
}

.deliveryZoneFailButtons {
  text-align: center;
  min-width: 280px;
  max-width: 280px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
}

.resetButton {
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 20px;
}

.deliveryFailButton {
}

.cssClass {
  display: none;
}

//SAVE ORDER MODAL

.saveOrderModalButton {
  margin-bottom: 20px;
}

.saveOrderContainer {
  display: inline-flex;
  flex: 1 1 300px;
  position: relative;
  border: 1px solid $secondary;
  border-radius: 5px;
  overflow: hidden;
  height: 51px;
  background-color: $fifthColor;
  text-align: center;
  min-width: 250px;
  margin-bottom: 15px;
}

//GeneralModal

.modal-dialog-end {
  display: flex;
  align-items: flex-end;
  min-height: calc(100vh - 2rem);
}

.popupHeader {
  color: $primary;
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  font-family: $boldFont;
}

.popupShell {
  background-color: $thirdColor;
  border-radius: 4px;
  border: none;
  text-align: center;
  height: 100%;
  padding: 1rem;
}

.popup-x {
  height: 15px;
  width: 15px;
  margin-right: 20px;
}

.close {
  position: absolute;
  top: 20px;
  right: 0;
}

.modifier-tabs {
  width: 80px;
  max-width: 80px;
  padding: 0 5px;
  height: 50px;
  text-align: center;
  border: 1px solid $secondary;
  border-right: 0.5px solid $secondary;
  border-left: 0.5px solid $secondary;
  cursor: pointer;
  background-color: white;
  display: grid;
  place-items: center;
}

.modifier-tabs-kiosk {
  padding: 0 5px;
  height: 50px;
  text-align: center;
  border: 2px solid #f15623;
  border-right: 1px solid #f15623;
  border-left: 1px solid #f15623;
  cursor: pointer;
  color: #f15623;
  font-family: $boldFont;
  display: grid;
  place-items: center;
}

.left-modifer-item {
  border-radius: 5px 0 0 5px;
}

.right-modifier-item {
  border-radius: 0 5px 5px 0;
}

.selected-submodifier {
  background-color: $primary;
  color: white;
}

.modifier-text-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modifier-text-kiosk {
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  font-family: $boldFont;
  text-transform: uppercase;
}

//RESET PASSWORD

.resetPasswordField {
  height: 51px;
  width: 100%;
  border: 1px solid $secondary;
  border-radius: 4px;
  padding: 6px 12px;
  color: $secondary;
}

//PAST ORDERS

.pastOrderShell {
  background-color: $thirdColor;
  border-radius: 4px;
  border: none;
  text-align: center;
  max-height: min(100vh, 500px);
  padding: 20px 0 0 0;
}

.pastOrderHeader {
  color: $primary;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-family: $boldFont;
  margin: 0;
}

.pastOrderPopupName {
  text-align: left;
  font-family: $boldFont;
  font-size: 18px;
}

.pastOrderPopupHeaderRow {
}

.pastOrderPopupButton {
  padding: 0px 15px;
}

.pastOrderPopupTotalRow {
  padding: 10px 15px;
}

.pastOrderPopupTotal {
  text-align: left;
  font-family: $boldFont;
}

.pastOrderPopupTotalPrice {
  text-align: right;
  font-family: $boldFont;
}

.pastOrders {
  overflow-y: auto;
  height: calc(min(100vh, 500px) - 144px);
}

.pastOrderItem {
  margin-bottom: 15px;
  padding: 0px 15px;
}

.pastOrderPopupPrice {
  text-align: right;
}

.pastOrderPopupItem {
  text-align: left;
}

.pastOrderPopupBottom {
  background-color: white;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
}

.removeFavoriteOrderPopup {
  margin-bottom: 20px;
  font-family: $boldFont;
  font-size: 14px;
}

.view-menu {
  text-align: right;
}

.save-card {
  input {
    margin-bottom: 10px;
    margin-right: 15px;
  }
}

.pac-container {
  z-index: 1060 !important;
}

.delivery {
  margin: 20px 40px 20px 40px;
}

.subLocName {
  font-size: 20px;
}

.pastLoc {
  background-color: white;
}

.add-more {
  padding: 20px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.confirmId {
  text-align: left;
  margin-bottom: 15px;
}

.use-gift-card {
  padding-top: 20px;
}

.gift-card-balance {
  padding-top: 0 0 10px 0;
}

.addAdditionPayment {
  margin-top: 15px;
}

// Media Queries

@media (max-width: 1494px) {
  .pickBut {
    margin-left: 0px;
    margin-top: 12px;
  }

  .locButtons {
  }
}

@media (max-width: 1265px) and (min-width: 992px) {
  .locationsMobileCont {
    display: inline;
  }

  .locationSection {
    display: none;
  }
}

@media (max-width: 1100px) {
  .customizeColRight {
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .collapse {
    display: inline-block;
  }

  .subnav {
    top: 139px;
  }

  .brand {
    position: absolute;
    left: 50%;
    margin: 0 0 0 -56.9px;
    top: 50px;
    display: block;
  }

  .logo-svg {
    height: 31.8px;
    width: 81.8px;
  }

  .navnav {
    box-shadow: none;
  }

  .headerHero {
    height: 70px;
    background-color: $sixthColor;
    padding: 0 0;
    //text-align: center;
  }

  .left {
    margin-top: 0px;
    color: white;
    padding: 0;
    width: 100%;
  }

  .right {
    display: none;
  }

  .pageHeader {
    font-size: 20px;
    color: white;
    display: inline;
    margin-left: -0px;
    padding-left: 0px;
  }

  .headerStuff {
    // display: inline;
    // margin: -15px;
  }

  .whitecolor {
    display: none;
  }

  .mobileSmallBack {
    display: inline-flex;
    float: left;
    margin-left: 40px;
  }

  .menubar {
  }
}

@media (min-width: 1000px) {
  .logo-svg {
    height: 70px;
    width: 180px;
  }
}

@media (max-width: 500px) {
  .noMap {
    margin: 90px 0px 0px 0px !important;
  }

  .locationsMap {
    height: 200px !important;
  }
}
.mapboxgl-ctrl-geocoder--input {
  width: 100% !important;
  max-width: 100% !important;

  @media (min-width: 640px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: 100% !important;

  @media (min-width: 640px) {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.map-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

@media (max-width: 991px) {
  .formDiv {
    margin-top: 85px;
  }

  .locationsPageCont {
    padding-top: 15px;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .rightMap {
    margin: 132px 30px 8px 30px;
    order: 1;
    padding: 0;
  }

  .leftLocations {
    order: 2;
  }

  .locationsMap {
    height: 200px !important;
  }

  .orderDetailsRightCol {
    min-height: 0;
    margin-top: 0px;
    padding: 0px 0px;
  }

  .detailsRightTop {
    display: none;
  }

  .lineBorderWeb {
    display: none;
  }

  .orderDetailsBottomTotals {
    position: fixed;
    bottom: 0px;
    width: 100%;
    right: 0px;
    box-shadow: 0px -3px 6px #00000029;
    padding: 20px 30px max(20px, env(safe-area-inset-bottom, 0));
  }

  .totalTextRow {
    margin-bottom: 15px;
  }

  .totalsText {
    padding-top: 0px;
  }

  .mobileBagItem {
    display: inline-flex;
    padding-top: 30px;
    margin-bottom: 200px;
    text-align: left;
    width: 100%;
  }

  .mobileBagItemNoInline {
    display: block;
    padding-top: 30px;
    margin-bottom: 0px;
    text-align: left;
    width: 100%;
  }

  .couponCodeMobile {
    display: flex;
    padding-bottom: 20px;
  }

  .couponCodeWeb {
    display: none;
  }

  .accountLeftCol {
    order: 2;
    padding-top: 0px;
    display: none;
  }

  .accountLeftColMobile {
    order: 2;
    display: inline;
  }

  .accountRightCol {
    order: 1;
    padding-top: 0px;
  }

  .accountDetails {
    background-color: transparent;
  }

  .accountDetailsLine {
    display: none;
  }

  .accountDetailsBottomBorder {
    display: inline;
    padding: 0px 15px;
  }

  .accountDetailsOption {
    display: none;
  }

  .leftSideItemsBag {
    display: inline;
  }

  .leftSideDivider {
    display: block;
  }

  .pastOrderRow {
    justify-content: center;
  }

  .savedCardsRow {
    justify-content: center;
  }

  .favoriteOrdersRow {
    justify-content: center;
  }
}

@media (max-width: 991px) and (min-width: 650px) {
  .locationsMobileCont {
    display: none;
  }

  .locationSection {
    display: flex;
  }
}

@media (max-width: 991px) {
  .returnToPunchButton {
    display: none;
  }

  .brand {
    position: absolute;
    left: 50%;
    margin: 0 0 0 -56.9px;
    top: calc(15px + env(safe-area-inset-top, 0));
    display: block;
  }

  .kiosk-brand {
    position: absolute;
    left: 50%;
    margin: 0 0 0 -100px;
    display: block;
    top: calc(15px + env(safe-area-inset-top, 0));

    .logo-svg {
      width: 175px;
      height: 100px;
    }
  }

  .returnToPunch {
    display: none;
  }

  .menuh2 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .menu-slide {
    display: none;
  }
}

@media (max-width: 700px) {
  .left {
    margin-left: 0px;
  }

  .bag-modal {
    .modal-dialog {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      position: absolute;
    }
  }

  .bag-item-container {
    overflow-y: inherit;
    margin-bottom: 200px;
    max-height: none;

    .small {
      font-size: 16px;
    }
  }

  .itemName {
    font-size: 16px;
  }

  .itemPrice {
    font-size: 16px;
  }

  .deliveryTipText {
    color: $fourthColor;
  }

  #toppingsText {
    font-size: 16px;
    padding-left: 0px;
  }

  .bottomBag {
    position: fixed;
    box-shadow: 0px -6px 6px #00000029;
    background-color: white;

    .deliveryFeeText,
    .add-more {
      color: black;
    }
  }

  .total-separator {
    border: 0.5px solid #333;
  }

  .subtotalText,
  .totalText {
    color: #333;
  }

  .bag-triangle {
    display: none;
  }

  .bag {
    max-width: 100%;
    min-height: calc(690px - env(safe-area-inset-bottom, 0));
    margin: 0;
    position: absolute;
    //top: calc(20px + env(safe-area-inset-top, 0));
    border-radius: 0;
  }

  .navbarBag {
    display: inline;
  }

  .bagHeaderHero {
    display: inline;
    height: 46px;
    background-color: $sixthColor;
    margin-top: calc(69px + env(safe-area-inset-top, 0));
  }

  .yourOrderMobile {
    text-align: center;
    color: white;
    font-family: $boldFont;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  .customizeContainer {
    overflow-x: auto;
    text-align: center;
    text-align: -webkit-center;
  }

  .title {
    display: none;
  }

  .insideBag {
    padding: 20px 20px;
  }

  .mobilecenteralign {
    text-align: center;
  }

  .locationInfoMobile {
    font-size: 14px;
  }
  .locationAddress {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .locationAddress {
    font-size: 11px;
  }
  .locationsColRight {
    div {
      font-size: 11px !important;
    }
  }
}

@media (max-width: 650px) {
  .locationsMobileCont {
    display: inline;
  }

  .locationSection {
    display: none;
  }

  .locationsPageCont {
    padding-left: 0px;
    padding-right: 0px;
  }

  .leftLocations {
    padding: 0px;
    overflow-y: hidden !important;
    height: 100%;
  }
}

@media (max-width: 500px) {
  .pageHeaderScroll {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 16px;
    text-align: center;
    width: 100%;
  }
}

//@media (min-width: 501px) {
//  .regularButton,
//  .regularButton {
//    width: 100% !important;
//  }
//}

@media (max-width: 500px) {
  regularButton {
    width: 50% !important;
    margin-top: 5px;
  }
}

.regularButton {
  @media (max-width: 320px) {
    min-width: 125px;
  }
  @media (max-width: 280px) {
    min-width: 100px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .orderDetailsBottomTotals {
    position: fixed;
    padding-right: 20px;
    padding-left: 20px;
    bottom: 0px;
    width: 100%;
    text-align: center;
    max-width: 1000px;
    min-width: 240px;
    right: 0px;
    background-color: white;
  }

  .couponCodeMobile {
    display: flex;
    padding-bottom: 20px;
  }
}

.modal-wrapper {
  padding: 20px;
  display: grid;
  place-items: center;
  background: #f6f2eb;
  border-radius: 4px;

  h1 {
    color: #f04e29;
    font-size: 20px;
  }

  .x-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: #665348;
  }

  div {
    font-size: 18px;
    width: 100%;

    p {
      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
  }
}

//FONT CSS
.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-30 {
  font-size: 30px !important;
}
/*
.bold {
  font-weight: 400;
}
*/
//KIOSK AND EXPO MODE

.expo-container {
  background-color: #ffffff;
  max-width: calc(100vw - 75px);
  margin: auto;
  height: 100%;
  padding: 50px 75px 75px;
  margin-bottom: 50px;
}

.error-container {
  background-color: #ffffff;
  max-width: calc(100vw - 75px);
  margin: auto;
  padding: 50px 75px;
}

.expo-form {
  margin-top: 175px;
}

// @media only screen and (orientation:landscape){
//   .kiosk-body {
//     height: 100vw;
//     transform: rotate(-90deg);
//   }
// }

.kioskCont {
  margin-top: 138px;
  align-content: center;
  touch-action: manipulation;
  border-radius: 5px;

  h2 {
    text-transform: uppercase;
    color: $primary;
    font-size: 30pt;
    font-family: "brandonBold";
  }

  h3 {
    text-transform: uppercase;
    color: $fourthColor;
    font-size: 24pt;
    font-family: "brandonBold";
    text-align: center;
  }

  h5 {
    text-transform: uppercase;
    color: $fourthColor;
    font-size: 16px;
    font-family: "brandonBold";
    text-align: center;
  }

  h6 {
    text-transform: uppercase;
    color: $fourthColor;
    font-size: 13px;
    font-family: "brandonBold";
    margin-bottom: 0px;
  }
}

.timeCont {
  border: none;
  border-radius: 4px;
  height: 40px;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "brandonBold", sans-serif;
  width: 125px;
  color: #333;
}

.expo-heading {
  border-bottom: 1pt solid #707070;
}

.checkinBtn {
  border-radius: 4px;
  background-color: #f04e29;
  color: #ffffff;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  width: 114px;
  font-family: "brandonBold", sans-serif;
}

.digDineBtn {
  min-width: 150px;

  @media (max-width: 500px) {
    width: 100% !important;
  }

  img {
    margin: auto;
    width: auto;
    max-width: 125px;
  }
}

.expoGhostButton {
  border-radius: 4px;
  background-color: white;
  color: #f04e29;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  width: 114px;
  font-family: "brandonBold", sans-serif;
  border: 1px solid #f04e29;

  img {
    max-width: 150px;
  }
}

.checkedBtn {
  border-radius: 4px;
  background-color: #00bdf2;
  color: #333333;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  width: 114px;
  font-family: "brandonBold", sans-serif;
}

.fireBtn {
  border-radius: 4px;
  width: 72px;
  background-color: #f04e29;
  color: #ffffff;
  height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: "brandonBold", sans-serif;
}

.fireBtn,
.checkedBtn,
.checkinBtn {
  &:hover,
  &:active {
    background-color: #de4624;
    color: white;
  }
}

.editBtn {
  color: $fourthColor;
  height: 30px;
}

.expoDetails {
  border-radius: 4px;
  height: 60px;

  &:nth-child(odd) {
    background: rgba(172, 160, 149, 0.3);
    z-index: 0;
  }

  &:nth-child(even) {
    background-color: #f6f2eb;
  }
}

.returnToKioskBtn {
  border-radius: 4px;
  min-width: 150px;
  height: 41px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  background-color: white;
  color: $primary;
  border: 1px solid $primary;
}

.kioskBasketBtn {
  border-radius: 4px;
  min-width: 100px;
  height: 41px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: $boldFont;
  background-color: $primary;
  color: white;
  border: 1px solid $primary;
}

.kioskBasketBtn:disabled {
  background-color: $fifthColor;
  border-color: $sixthColor;
  border-width: 1px;
  color: $sixthColor;
  font-weight: bold;
}

.logoutBtn {
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  background-image: url("assets/images/person-sharp.svg");
  background-repeat: no-repeat;
}

.modal-title {
  font-size: 32px !important;
}

.bold-modal-txt {
  font-family: "brandonBold", sans-serif;
  font-size: 18px;
}

.party-dropdown {
  width: 56px;
  background-color: $fifthColor;
  border-color: #aca095;
  color: #333333;
  border-width: 1px;
}

.party-dropdown-queue {
  width: 100%;
  background-color: $fifthColor;
  border-color: #aca095;
  color: #333333;
  border-width: 1px;
}

.expoFixed {
  background-color: #f6f2eb;
  z-index: 100;
  overflow-y: auto;
}

// .expoScroll {
//   padding-top: 75px;

//   @media (max-width: 991px) {
//     padding-top: 130px;
//   }
// }

.kiosk-btn {
  width: 100% !important;
  min-width: 90px !important;
  color: $primary;
  font-size: 12px;

  &:hover {
    color: #ffffff;
    background-color: #e33710;
  }
}

.kiosk-fill-btn {
  background-color: $primary;
  color: #ffffff;
}

.kiosk-menu-title {
  height: 87px;
  color: #ffffff;
  background-color: #aca095;
  border-radius: 4px;
  margin-top: 90px;

  h3 {
    color: #ffffff;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    padding: 10px 5px;
    display: inline-block;
    font-weight: 700;
  }
}

.kiosk-card {
  margin: 0;
  padding: 0;
  text-align: center;
  min-height: auto;
  height: 100%;
  margin: 0px auto;
  background-color: $fifthColor;
  border: none;
  padding: 0;
  border-radius: 4px;

  .title-row {
    height: 75px;
    max-width: 270px;
  }

  .img-container {
    place-self: center;
    text-align: center;
    text-align: -webkit-center;
  }
}

.kiosk-card-img {
  overflow: hidden;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: flex;
  border-radius: 4px;
  padding: 0;
  width: 115px;
  height: 115px;
  position: relative;
}

.kioskCardsRow {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  margin: 0px 0px;
  justify-items: center;
  margin-bottom: 100px;
  margin: 20px 10px;
  max-height: 100px;
  width: 100%;
  position: relative;

  @media (min-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.kiosk-card {
  background-color: $fifthColor;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin: 0px auto;
  border-radius: 4px;
}

.kiosk-btn {
  width: 100% !important;
  min-width: 90px !important;
  font-size: 12px !important;
  color: $primary;
}

.kiosk-fill-btn {
  background-color: $primary;
  color: #ffffff;
}

.kiosk-chkout-title {
  color: $primary;
  font-family: "brandonBold", sans-serif;
  font-size: 32px;
  text-transform: uppercase;
}

.kiosk-chkout-prod-name {
  color: #333333;
  font-family: "brandonBold", sans-serif;
  font-size: 20px;
}

.kiosk-checkout-btn {
  background-color: $primary;
  color: white;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 4px;
  font-family: "brandonBold", sans-serif;
  text-transform: uppercase;
}

.kiosk-checkout-btn-ghost {
  background-color: white;
  color: $primary;
  width: 100%;
  height: 50px;
  border: 1px solid $primary;
  border-radius: 4px;
  font-family: "brandonBold", sans-serif;
  text-transform: uppercase;
}

.kiosk-logo {
  padding: 50px 0 25px 0;
}

.kiosk-input-field {
  width: 100%;
  height: 50px;
  border: 1px solid #333333;
  border-radius: 4px;
}

.nav-dropdown {
  width: 125px;
  background-color: $fifthColor;
  border-color: #aca095;
  color: #333333;
  border-width: 1px;
}

.time-remaining-bar {
  background-color: #538ac5;
  color: white;
  font-size: 18px;
}

.disabled-modifier {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;
}

.toast-top-right {
  top: calc(12px + env(safe-area-inset-top)) !important;
  right: calc(12px + env(safe-area-inset-right)) !important;
}

input.mapboxgl-ctrl-geocoder--input {
  font-family: $regularFont;
}

.past-orders-datepicker {
  border: 1px solid #665348;
}

.wait-time-background {
  background-image: url("assets/images/wait-time-background.png");
  background-size: cover;
  background-position: center;
  min-width: 100vw;
  min-height: 100vh;
}

// Badge CSS

.badge {
  &-open {
    background-color: $primary;
  }
  &-closed {
    background-color: $fourthColor;
  }
}

/* font colors */
.text-pp-orange {
  color: var(--pp-orange) !important;
}

.text-pp-yellow {
  color: var(--pp-yellow) !important;
}

.text-pp-blue {
  color: var(--pp-blue) !important;
}

.text-pp-black {
  color: var(--pp-black) !important;
}

.text-pp-dark-brown {
  color: var(--pp-dark-brown) !important;
}

.text-pp-dark-tan {
  color: var(--pp-dark-tan) !important;
}

.text-pp-light-tan {
  color: var(--pp-light-tan) !important;
}

.arrow {
  padding: 0.5rem;
  border-radius: 0;
  cursor: pointer;
  //box-shadow: 0 1px 6px #00000029;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out, visibility 0.2s linear 0.2s;

  i {
    height: 1rem;
    width: 1rem;
  }

  &.button-left {
    position: absolute;
    top: 4px;
    left: 0;
    bottom: 0;
    z-index: 2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(
      -90deg,
      rgba(214, 208, 202, 0.5) 50%,
      rgba(214, 208, 202, 0.75) 75%,
      rgba(214, 208, 202, 1) 100%
    );
  }

  &.button-right {
    position: absolute;
    top: 4px;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    background: linear-gradient(
      90deg,
      rgba(214, 208, 202, 0.5) 50%,
      rgba(214, 208, 202, 0.75) 75%,
      rgba(214, 208, 202, 1) 100%
    );
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s linear 0.2s;
  }
}

.w-fit-content {
  width: fit-content !important;
}

.menu-card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 1rem;
}

@media (max-width: 700px) {
  .menu-card-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: 1rem;
  }
}

div.olo-pay div.__PrivateStripeElement {
  flex-grow: 1;
  height: 20px;
}

input::placeholder {
  color: #000000 !important;
  opacity: 0.5 !important;
}

.cursor-pointer {
  cursor: pointer;
}
